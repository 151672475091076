<template>

<app-page :no-padding="true">

	<com-summary />
	<com-selection />
	<com-save />
	<com-info />
	<com-foot />

</app-page>

</template>

<script>

export default {

	components: {
		'com-summary': () => import('./recommendation/Summary'),
		'com-selection': () => import('./recommendation/Selection'),
		'com-save': () => import('./recommendation/Save'),
		'com-info': () => import('./recommendation/Info'),
		'com-foot': () => import('./recommendation/Foot')
	},

	created: async function() {

		await this.$store.dispatch('recommend')

	}

}

</script>

<style scoped>

</style>
